<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p>{{ buyer }}</p>
			<p class="info__address">{{ giftCard.currency }}</p>
			<template v-if="giftCard.recipient == 'third_party'">
				<p>{{ giftCard.recipient_name }}</p>
			</template>
			<template v-else-if="giftCard.recipient == 'self'">
				<p>Self</p>
			</template>
			<p class="info__address">
				{{ giftCard.amount | currencyFormatter(giftCard.currency) }}
			</p>
			<p class="info__address">
				<span :class="statusStyle" class="pad">
					{{ giftCard.is_used === 1 ? 'Used' : 'Unused' }}
				</span>
			</p>
			<p>
				<router-link
					:to="{ name: 'giftCards-details', params: { id: giftCard.id } }"
					class="btn btn__primary"
					id="giftCardDetail"
				>
					View Details
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GiftCardItem',
	props: {
		giftCardData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			giftCard: this.giftCardData,
			itemData: '',
			mapStatus: ''
		};
	},
	filters: {
		currencyFormatter(val, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
				minimumFractionDigits: 2
			});
			return formatter.format(val);
		}
	},
	computed: {
		buyer() {
			return `${this.giftCardData.buyer.firstname} ${this.giftCardData.buyer.lastname}`;
		},
		statusStyle() {
			return this.giftCardData.is_used === 0 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 1fr 1fr 1fr 1fr 1fr 0.4fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
.pad {
	padding: 5px 10px;
}
</style>
