import { render, staticRenderFns } from "./GiftCardItem.vue?vue&type=template&id=580cd548&scoped=true&"
import script from "./GiftCardItem.vue?vue&type=script&lang=js&"
export * from "./GiftCardItem.vue?vue&type=script&lang=js&"
import style0 from "./GiftCardItem.vue?vue&type=style&index=0&id=580cd548&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580cd548",
  null
  
)

export default component.exports