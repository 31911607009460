<template>
	<div>
		<template v-if="loadingGiftCards">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Gift Card</h3>
				</div>
				<div class="form__search-wrapper">
					<label class="form__label" for="search-user">
						<input
							id="search-user"
							class="form-control form__input form__search"
							type="search"
							v-model="query"
							@keyup.enter="search"
							placeholder="Type Gift Card Code in to search for a Gift Card"
						/>
						<span class="form__search__icon">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<title>Search Icon</title>
								<path
									d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 
									9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 
									0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
									fill="#828282"
								/>
							</svg>
						</span>
					</label>
					<small class="error-feedback text-danger">{{ queryError }}</small>

					<button v-if="!searchLoaded" @click="displayAll" class="py-1 btn btn__primary rounded">
						View all
					</button>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<p>#</p>
						<p>Buyer</p>
						<p>Currency</p>
						<p>Recipient</p>
						<p>Amount</p>
						<p>Status</p>
						<p>Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="giftCardsLoaded && searchLoaded && giftCards.total > 0">
							<GiftCardItem
								v-for="giftCard in giftCards.data"
								:key="giftCard.id"
								:giftCardData="giftCard"
							/>
						</template>
						<template v-else-if="!searchLoaded && getSearchGiftCards.length > 0">
							<GiftCardItem
								v-for="giftCard in getSearchGiftCards"
								:key="giftCard.id"
								:giftCardData="giftCard"
							/>
						</template>
						<template v-else-if="!searchLoaded && getSearchGiftCards.length === 0">
							<p class="mt-5 pl-5">No Gift Card found</p>
						</template>
						<template v-else-if="giftCardsLoaded">
							<p class="mt-5 pl-5">No Gift Card found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Gift Cards, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import GiftCardItem from './GiftCardItem';

export default {
	name: 'GiftCards',
	components: {
		Loader,
		Pagination,
		GiftCardItem
	},
	data() {
		return {
			giftCardsLoaded: false,
			loadingGiftCards: true,
			offset: 0,
			limit: 10,
			baseUrl: 'giftCards',
			query: '',
			searchLoaded: true,
			total: 0,
			queryError: ''
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllGiftCards([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				this.loading = false;
				this.loadingGiftCards = false;
				this.query = '';
				this.giftCardsLoaded = true;
				this.total = this.searchLoaded ? this.totalGiftCards : this.searchTotal;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllGiftCards([this.limit, this.offset]);
		},
		query() {
			this.queryError = '';
		}
	},
	computed: {
		...mapGetters({
			giftCards: 'getGiftCards',
			status: 'getStatus',
			totalGiftCards: 'getTotalGiftCards',
			getSearchGiftCards: 'getGiftCardsSearch',
			getSearchGiftCardsTotal: 'getTotalSearchGiftCards'
		})
	},
	methods: {
		...mapActions(['fetchAllGiftCards', 'searchGiftCards']),
		search() {
			if (this.query && this.query.length === 0) {
				this.fetchAllGiftCards([this.limit, this.offset]);
				this.searchLoaded = true;
			} else if (this.query && this.query.length < 22) {
				this.queryError = 'Invalid input';
			} else {
				this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
				this.offset =
					this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
				const data = { limit: this.limit, offset: this.offset, query: this.query };
				this.searchGiftCards(data);
				this.searchLoaded = false;
				this.total = this.searchTotal;
				this.queryError = '';
			}
		},
		displayAll() {
			this.fetchAllGiftCards([this.limit, this.offset]);
			this.total = this.totalGiftCards;
			this.searchLoaded = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 1fr 1fr 1fr 1fr 1fr 0.4fr !important;
}
</style>
